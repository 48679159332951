import styled from "styled-components";

const StyledImage = styled.img`
  width: 100%;
  height: 50px;
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    height: 100px;
  }
`;

const AdWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space[4]};
`;

interface IProps {
  adType: string;
}

const PageAd = ({ adType }: IProps) => {
  return (
    <AdWrap>
      <StyledImage
        src={`${process.env.PUBLIC_URL}/img/ads/page-ad-${adType}.svg`}
        alt=""
      />
    </AdWrap>
  );
};

export default PageAd;
