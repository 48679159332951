import { ThunkResult } from "..";
import { apiDelete, apiGet, apiPost, apiPut } from "../../utils/api";
import { bootstrap } from "../bootstrap/thunks";
import * as actions from "./actions";
import {
  ILoginData,
  IPasswordChangeData,
  IPasswordResetData,
  IPasswordSetData,
} from "./types";

export const login =
  (postData: ILoginData): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiPost("player/login/", postData, dispatch, actions.login);
    } catch (e) {
      return;
    }
    dispatch(bootstrap());
  };

export const logout = (): ThunkResult<Promise<void>> => (dispatch) =>
  apiGet("player/logout/", dispatch, actions.logout);

export const register =
  (postData: {}): ThunkResult<Promise<void>> =>
  (dispatch) =>
    apiPost("player/create/", postData, dispatch, actions.register);

export const playerUpdate =
  (postData: {}): ThunkResult<Promise<void>> =>
  (dispatch) =>
    apiPut("player/update/", postData, dispatch, actions.playerUpdate);

export const passwordChange =
  (postData: IPasswordChangeData): ThunkResult<Promise<void>> =>
  (dispatch) =>
    apiPut(
      "player/password-change/",
      postData,
      dispatch,
      actions.passwordChange
    );

export const passwordReset =
  (postData: IPasswordResetData): ThunkResult<Promise<void>> =>
  (dispatch) =>
    apiPost(
      "player/password-reset/",
      postData,
      dispatch,
      actions.passwordReset
    );

export const passwordSet =
  (postData: IPasswordSetData): ThunkResult<Promise<void>> =>
  (dispatch) =>
    apiPut("player/password-set/", postData, dispatch, actions.passwordSet);

export const addToWatchlist =
  (elementCode: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    dispatch(actions.addToWatched(elementCode));
    return apiPost(
      `watchlist/${elementCode}/`,
      {},
      dispatch,
      actions.addToWatchlist
    );
  };

export const removeFromWatchlist =
  (elementCode: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    dispatch(actions.removeFromWatched(elementCode));
    return apiDelete(
      `watchlist/${elementCode}/`,
      {},
      dispatch,
      actions.removeFromWatchlist
    );
  };

export const accountRecovery =
  (postData: ILoginData): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiPost("player/recovery/", postData, dispatch, actions.recover);
    } catch (e) {
      return;
    }
    dispatch(bootstrap());
  };
