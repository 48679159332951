import { createAsyncAction, deprecated } from "typesafe-actions";
import { IError } from "..";

const createAction = deprecated.createAction;

export const login = createAsyncAction(
  "LOGIN_START",
  "LOGIN_SUCCESS",
  "LOGIN_FAILURE"
)<void, object, IError>();

export const logout = createAsyncAction(
  "LOGOUT_START",
  "LOGOUT_SUCCESS",
  "LOGOUT_FAILURE"
)<void, object, IError>();

export const recover = createAsyncAction(
  "RECOVER_START",
  "RECOVER_SUCCESS",
  "RECOVER_FAILURE"
)<void, object, IError>();

export const register = createAsyncAction(
  "REGISTER_START",
  "REGISTER_SUCCESS",
  "REGISTER_FAILURE"
)<void, object, IError>();

export const playerUpdate = createAsyncAction(
  "PLAYER_UPDATE_START",
  "PLAYER_UPDATE_SUCCESS",
  "PLAYER_UPDATE_FAILURE"
)<void, object, IError>();

export const passwordChange = createAsyncAction(
  "PASSWORD_CHANGE_START",
  "PASSWORD_CHANGE_SUCCESS",
  "PASSWORD_CHANGE_FAILURE"
)<void, object, IError>();

export const passwordReset = createAsyncAction(
  "PASSWORD_RESET_START",
  "PASSWORD_RESET_SUCCESS",
  "PASSWORD_RESET_FAILURE"
)<void, object, IError>();

export const passwordSet = createAsyncAction(
  "PASSWORD_SET_START",
  "PASSWORD_SET_SUCCESS",
  "PASSWORD_SET_FAILURE"
)<void, object, IError>();

export const addToWatched = createAction(
  "ADD_TO_WATCHED",
  (resolve) => (elementCode: number) => resolve(elementCode)
);

export const removeFromWatched = createAction(
  "REMOVE_FROM_WATCHED",
  (resolve) => (elementCode: number) => resolve(elementCode)
);

export const addToWatchlist = createAsyncAction(
  "ADD_TO_WATCHLIST_START",
  "ADD_TO_WATCHLIST_SUCCESS",
  "ADD_TO_WATCHLIST_FAILURE"
)<void, {}, IError>();

export const removeFromWatchlist = createAsyncAction(
  "REMOVE_FROM_WATCHLIST_START",
  "REMOVE_FROM_WATCHLIST_SUCCESS",
  "REMOVE_FROM_WATCHLIST_FAILURE"
)<void, {}, IError>();
